
md-input-container.md-umbrella-theme {
	margin-top: 10px;
	margin-bottom: 10px;

	.md-input {
		border-color: rgba(0,0,0,0.3);
	}

	label, .md-select-placeholder {
		color: rgba(0,0,0,0.8);
	}

}


md-select.md-umbrella-theme {

	.md-select-value {
		border-color: rgba(0,0,0,0.3);
	}

	.md-select-value.md-select-placeholder {
		color: rgba(0,0,0,0.8);
	}
}

md-select.md-umbrella-theme .md-select-value.md-select-placeholder{}

md-autocomplete.md-umbrella-theme {
	margin-top: 0; 
}

md-radio-group.md-umbrella-theme, md-checkbox.md-umbrella-theme {

	.md-label {
		h1, h2, h3, h4, h5 { margin: 0; }
	}
}

md-switch[disabled] {
    cursor: not-allowed;

    .md-container {
    cursor: not-allowed;
}
}