main-header {
	box-shadow: 0px 1px 5px rgba(0,0,0,0.2);
	z-index: 5;
}

.umb-view, .umb-view > md-content > div {
    height: 100%;
}

md-divider.md-umbrella-theme {
	border-top-color: rgba(0,0,0,0.25);
}
.layout-gt-md-row > md-divider.md-umbrella-theme {
	border-right-color: rgba(0,0,0,0.25);
}

md-content.md-umbrella-theme {
	background: transparent;
}

.md-inline-form md-content.md-umbrella-theme {
    // border-bottom: 1px solid rgba(0,0,0,0.2);
}

md-content.md-padding {
    padding: 20px 50px;
}

//Adds some extra white space to the bottom of the content column
md-content.md-umbrella-theme.content-column {
	padding-bottom: 100px;
}

md-content[class^="umb-sidebar"] {
	background-color: rgba(0,0,0,0.05);
	width: 320px;
}

md-content.umb-sidebar-right {
	border-left: 1px solid rgba(0,0,0,0.2);
}

md-content.umb-sidebar-left {
	border-right: 1px solid rgba(0,0,0,0.2);
}

md-content.umb-sidebar-left span {
	text-align: center;
}

md-table-container.umb-no-bottom-border {
	border-bottom: none;
}


.umb-left-padding {
	padding-left: 40px;
}
.umb-right-padding {
	padding-right: 40px;
}
.umb-padding {
	// padding-left: 30px;
	// padding-right: 30px;
	padding: 40px 30px;
}

.umb-max-width {
	width: 960px;
}