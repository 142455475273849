/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
@import url(//fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic);
/*   
Site Name: Umbrella App

Author: Arissberg
Version: 1.0
*/
main-header {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.umb-view, .umb-view > md-content > div {
  height: 100%;
}

md-divider.md-umbrella-theme {
  border-top-color: rgba(0, 0, 0, 0.25);
}

.layout-gt-md-row > md-divider.md-umbrella-theme {
  border-right-color: rgba(0, 0, 0, 0.25);
}

md-content.md-umbrella-theme {
  background: transparent;
}

md-content.md-padding {
  padding: 20px 50px;
}

md-content.md-umbrella-theme.content-column {
  padding-bottom: 100px;
}

md-content[class^="umb-sidebar"] {
  background-color: rgba(0, 0, 0, 0.05);
  width: 320px;
}

md-content.umb-sidebar-right {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

md-content.umb-sidebar-left {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

md-content.umb-sidebar-left span {
  text-align: center;
}

md-table-container.umb-no-bottom-border {
  border-bottom: none;
}

.umb-left-padding {
  padding-left: 40px;
}

.umb-right-padding {
  padding-right: 40px;
}

.umb-padding {
  padding: 40px 30px;
}

.umb-max-width {
  width: 960px;
}

.md-display-1 {
  line-height: 0;
  padding-bottom: 10px;
}

h2 {
  margin-top: 0;
  font-size: 1.4em;
}

h2 .material-icons {
  color: #6e297a;
  font-size: 1.4em;
}

h3, h4 {
  display: inline-block;
  width: 100%;
}

h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 10px 0;
  color: #000000;
  text-align: left;
}

h4 {
  margin-bottom: 1em;
}

p {
  line-height: 1.6em;
  margin-top: 0;
  margin-bottom: 1em;
}

type, .type {
  color: #6e297a;
  font-size: 70%;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}

md-toolbar {
  padding: 6px 8px;
  min-height: 0;
}

md-toolbar md-input-container {
  font-size: 16px;
  color: #fff;
}

.list-toolbar .add-button {
  position: absolute;
  transform: translateY(-15%);
}

.list-toolbar .add-button md-icon {
  color: #6e297a !important;
}

.list-toolbar md-select.md-umbrella-theme .md-select-value {
  border-color: transparent;
}

.list-toolbar md-input-container.md-umbrella-theme {
  margin: 0;
}

.list-toolbar md-input-container.md-umbrella-theme input {
  color: #fff !important;
}

.list-toolbar md-input-container.md-umbrella-theme input:input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.list-toolbar md-input-container.md-umbrella-theme input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.list-toolbar md-input-container.md-umbrella-theme input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.list-toolbar md-input-container.md-umbrella-theme input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.list-toolbar md-input-container.md-umbrella-theme .roleFilter {
  margin-left: 10%;
}

.list-toolbar md-input-container.md-umbrella-theme .statusFilter {
  margin-left: 20%;
}

.list-toolbar md-input-container.md-umbrella-theme .md-input, .list-toolbar md-input-container.md-umbrella-theme.md-input-focused .md-focus {
  font-size: 120%;
  border-color: transparent !important;
}

md-input-container.md-umbrella-theme {
  margin-top: 10px;
  margin-bottom: 10px;
}

md-input-container.md-umbrella-theme .md-input {
  border-color: rgba(0, 0, 0, 0.3);
}

md-input-container.md-umbrella-theme label, md-input-container.md-umbrella-theme .md-select-placeholder {
  color: rgba(0, 0, 0, 0.8);
}

md-select.md-umbrella-theme .md-select-value {
  border-color: rgba(0, 0, 0, 0.3);
}

md-select.md-umbrella-theme .md-select-value.md-select-placeholder {
  color: rgba(0, 0, 0, 0.8);
}

md-autocomplete.md-umbrella-theme {
  margin-top: 0;
}

md-radio-group.md-umbrella-theme .md-label h1, md-radio-group.md-umbrella-theme .md-label h2, md-radio-group.md-umbrella-theme .md-label h3, md-radio-group.md-umbrella-theme .md-label h4, md-radio-group.md-umbrella-theme .md-label h5, md-checkbox.md-umbrella-theme .md-label h1, md-checkbox.md-umbrella-theme .md-label h2, md-checkbox.md-umbrella-theme .md-label h3, md-checkbox.md-umbrella-theme .md-label h4, md-checkbox.md-umbrella-theme .md-label h5 {
  margin: 0;
}

md-switch[disabled] {
  cursor: not-allowed;
}

md-switch[disabled] .md-container {
  cursor: not-allowed;
}

.umb-primary {
  color: #FFF !important;
  background-color: #6e297a;
  padding: 0px 20px;
}

.umb-primary.small {
  min-height: 22px;
  line-height: 27px;
  font-size: 11px;
}

.umb-primary:hover {
  background-color: rgba(110, 41, 122, 0.5) !important;
}

.md-button.md-umbrella-theme.md-fab.add-button {
  background-color: #fafafa !important;
  color: #333 !important;
}

table th.md-column:last-of-type, table td.md-cell:last-of-type {
  width: 15%;
  text-align: center;
}

table th.md-column md-icon, table td.md-cell md-icon {
  color: #6e297a;
}

md-table-container {
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.md-table thead.md-head > tr.md-row {
  height: 45px;
}

table.md-table th.md-column {
  color: rgba(0, 0, 0, 0.8);
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
  display: block;
}

hr {
  margin-bottom: 30px;
}

md-tabs.umb-person-tabs {
  border-radius: 0;
  background-color: #9738a8;
  color: rgba(255, 255, 255, 0.87);
}

md-tabs.umb-person-tabs .md-tab {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}

md-tabs.umb-person-tabs .md-tab.md-active {
  color: #fff;
  background: #a83ebb;
}

md-tabs.umb-person-tabs .md-tab[disabled] {
  cursor: not-allowed;
  color: #fff;
}

md-tabs.umb-person-tabs md-ink-bar {
  height: 4px;
  color: #d49bde;
  background: #d49bde;
}

.action-message {
  text-align: center;
  padding: 2em;
  cursor: initial;
}

.action-message .msg-container {
  text-align: left;
  display: inline-block;
  padding: 2em;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.action-message h2 {
  text-align: center;
}

.action-message h3 {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-top: 0;
  padding-bottom: 0.5em;
  text-align: center;
  border-bottom: 1px solid #6e297a;
}

.action-message ul, .action-message ol {
  padding-right: 40px;
}

.action-message li {
  margin-bottom: 0.25em;
}

.flag {
  padding: 1.2% 0.5em;
  white-space: nowrap;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  background: #ccc;
  font-size: 70%;
  text-transform: uppercase;
  position: relative;
}

.flag.purple {
  color: #fff;
  background: #6e297a;
}

.umb-calendar {
  position: relative;
}

.umb-calendar .calendar-headers {
  width: 100%;
  font-size: 12px;
  line-height: 3em;
  font-weight: bold;
  border-bottom: 2px solid rgba(106, 27, 154, 0.4);
}

.umb-calendar .selected {
  -webkit-print-color-adjust: exact;
  border-radius: 40px !important;
  color: #ffffff;
  background-color: #6a1b9a;
  font-weight: bold !important;
}

.umb-calendar .selected.full-time-rate {
  box-shadow: 0 0 0px 5px #ea80fc;
}

.umb-calendar .closed {
  background-color: #cccccc;
}

.umb-calendar .closed.selected {
  color: inherit;
  background-color: #cccccc;
}

.umb-calendar .fullday.selected:not(.closed) {
  color: #ffffff;
}

.umb-calendar .available.before, .umb-calendar .available.after {
  position: relative;
  overflow: hidden;
}

.umb-calendar .available.before:after, .umb-calendar .available.after:after {
  content: "";
  backface-visibility: hidden;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 50px 50px 0 0;
  transition: transform 0.25s ease;
}

.umb-calendar .available.before:after {
  background-color: #4db4dc;
}

.umb-calendar .available.after:after {
  background-color: #d7b928;
}

.umb-calendar .available.before::after, .umb-calendar .available.closedbefore::after {
  transform: rotate(135deg);
  transform-origin: bottom;
}

.umb-calendar .available.before::after span, .umb-calendar .available.closedbefore::after span {
  transform: rotate(-135deg);
  display: block;
}

.umb-calendar .available.after::after, .umb-calendar .available.closedafter::after {
  transform: rotate(-45deg);
  transform-origin: bottom;
}

.umb-calendar .available.after::after span, .umb-calendar .available.closedafter::after span {
  transform: rotate(45deg);
  display: block;
}

.umb-calendar .available.paday, .umb-calendar .available.paday[disabled] {
  background-color: #69be69;
  color: #ffffff;
  opacity: 1;
}

.umb-calendar .available.schoolbreak, .umb-calendar .available.schoolbreak[disabled] {
  background-color: #ffa235;
  color: #ffffff;
  opacity: 1;
}

.umb-calendar .invoice-date {
  width: 182px;
  display: inline-table;
}

.umb-calendar .invoice-date i {
  display: block;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.umb-calendar .schedule {
  width: 100%;
}

.umb-calendar .schedule:not(:last-of-type) {
  margin-bottom: 2em;
}

.umb-calendar .schedule .schedule-title {
  margin: 1em 0;
  padding-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.umb-calendar .schedule .schedule-title span {
  position: absolute;
  transform: translateY(-50%);
  background: #fff;
  padding-right: 1em;
}

.umb-calendar .quick-selection md-input-container {
  margin: 0 1em 0 0;
}

.umb-calendar .quick-selection md-input-container > span {
  display: block;
  font-size: 12px;
  padding-bottom: 8px;
}

.umb-calendar .quick-selection md-checkbox {
  margin-right: 1.1em;
}

.umb-calendar .quick-selection md-checkbox .md-label {
  font-size: 14px;
}

.umb-calendar .legend {
  width: 100%;
  margin: 1.2em 0 1.5em;
}

.umb-calendar .legend .item {
  display: inline-block;
  margin-right: 24px;
}

.umb-calendar .legend .full-time {
  padding-right: 1.5em;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.umb-calendar .legend i {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 7px;
}

.umb-calendar .legend label {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  opacity: 0.6;
}

.umb-calendar .cards-calendar-days {
  width: 264px;
}

.umb-calendar .calendar md-card {
  margin: 0;
}

.umb-calendar .calendar .invoice-date {
  position: absolute;
  transform: translateX(-194px);
}

.umb-calendar .calendar md-card-header {
  padding: 0 12px;
  border-bottom: 2px solid rgba(110, 41, 122, 0.4);
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.umb-calendar .calendar md-card-header .invoice-date {
  line-height: 3em;
  border-bottom: 2px solid rgba(110, 41, 122, 0.4);
  text-align: left;
}

.umb-calendar .calendar md-card-header .invoice-date i {
  display: block;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.umb-calendar .calendar md-card-header .day-of-week {
  min-width: 40px;
  margin: 0 4px;
  line-height: 3em;
}

.umb-calendar .calendar md-card-actions {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 6px 12px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.umb-calendar .calendar md-card-actions .invoice-date {
  height: 100%;
}

.umb-calendar .calendar md-card-actions .invoice-date span {
  display: block;
  margin-top: 20px;
}

.umb-calendar .calendar md-card-actions .invoice-date:not(.first) {
  top: -1px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.umb-calendar .calendar .md-button.day {
  padding: 0;
  margin: 4px;
  min-height: 40px;
  min-width: 40px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.umb-calendar .calendar .md-button.day:hover {
  background-color: #6a1b9a;
  opacity: .5;
  color: #fff;
}

.umb-calendar .calendar .md-button.day:first-of-type, .umb-calendar .calendar .md-button.day:last-of-type {
  margin: 4px;
}

.umb-calendar .calendar .md-button.day span {
  position: relative;
  z-index: 5;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

html {
  font-family: 'Roboto', 'Helvetica Neue', serif;
}

[layout=row] {
  flex-direction: row;
}

.umb-sidebar-left a, .umb-sidebar-left a:active, .umb-sidebar-left a:visited {
  color: #6e297a;
  text-decoration: none;
}

.umb-sidebar-left a:hover, .umb-sidebar-left a:active:hover, .umb-sidebar-left a:visited:hover {
  color: #5d2367;
  text-decoration: underline;
}

input[type=text] {
  text-transform: capitalize;
}

md-autocomplete {
  margin-top: 40px;
  padding-left: 30px;
  display: inline-block;
  width: 50%;
}

md-autocomplete input {
  min-height: 32px;
}

md-autocomplete[disabled] label {
  color: rgba(0, 0, 0, 0.38);
}

md-autocomplete i.material-icons, md-autocomplete md-progress-circular {
  position: absolute;
  top: 10px;
  left: -30px;
}

md-input-container.md-umbrella-theme {
  padding-right: 20px;
}

table.md-table td.md-cell {
  font-size: 14px;
}

table.md-table.md-row-select td.md-cell:first-child {
  padding-left: 14px;
}

md-card.md-umbrella-theme {
  background-color: #f8f8f8;
}

.md-datepicker-input {
  max-width: none;
}

.md-datepicker-input-container {
  width: 100%;
}

md-optgroup label {
  background: #6e297a;
  color: #fff;
}

md-optgroup md-option .md-text span {
  font-weight: bold;
}

md-dialog.md-umbrella-theme {
  min-width: 600px;
}

md-dialog.md-umbrella-theme .md-dialog-content-body {
  line-height: 1.5em;
}

.flex-button-container button {
  margin-left: 2px;
  margin-right: 2px;
}

.umb-add-container {
  padding: 1.5em 1.5em;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fffbf5;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.umb-add-container md-input-container .md-errors-spacer {
  min-height: auto;
}

.umb-add-container md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: 8px !important;
}

.person-sidebar h2 {
  width: 100%;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 0.8em;
}

.person-sidebar h2 span {
  text-align: left;
  display: block;
  font-size: 12px;
}

.person-sidebar p {
  margin-bottom: 0;
}

.person-sidebar a {
  font-weight: 700;
}

.person-sidebar md-icon {
  font-size: 18px;
}

.person-sidebar section {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  text-align: left;
  line-height: 20px;
}

.person-sidebar section > div {
  padding: 8px 0;
  display: flex;
  align-items: stretch;
  align-content: space-between;
}

.person-sidebar section > div > *:first-child {
  padding-right: 0.25em;
}

.person-sidebar .account-balance strong {
  font-size: 160%;
}

.person-sidebar .payment-information p {
  text-align: center;
}

.person-sidebar.adult-sidebar {
  padding-top: 1.6em;
}

.person-sidebar.adult-sidebar h2 {
  text-align: left;
}

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    margin: 5mm 10mm;
    /* this affects the margin in the printer settings */
  }
  main-header, .person-sidebar, .umb-person-tabs {
    display: none !important;
  }
}

/**
 *  Do not remove the comment below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
.pac-container.pac-logo:after {
  display: none !important;
}

.login {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: url(../assets/img/login-bg.jpg) center center no-repeat;
  background-size: cover;
}

.login md-card-header-text {
  text-align: center;
}

.login .logo img {
  margin-top: 1em;
  max-width: 180px;
}

.login .md-title {
  max-width: 300px;
}

md-table-container .md-row-select {
  cursor: pointer;
}

md-table-container th {
  border-bottom: 2px solid purple;
}

md-table-container.no-checkbox .md-checkbox-column, md-table-container.no-checkbox .md-checkbox-cell {
  display: none;
}

md-table-container tr.deleted {
  text-decoration: line-through;
}

md-table-container tr.deleted .md-cell {
  color: rgba(0, 0, 0, 0.4);
}

md-table-container .action-link {
  cursor: pointer;
  display: block;
  z-index: 10;
  position: relative;
  background: url("../assets/img/blank.png");
  padding: 10px;
  white-space: nowrap;
  color: #6e297a;
  text-transform: uppercase;
  font-weight: 500;
}

md-table-container .action-link:hover {
  text-decoration: none;
  opacity: 0.8;
}

md-table-container .md-cell {
  line-height: 1.4em;
}

md-table-container td.md-cell.center, md-table-container th.md-column.center {
  text-align: center;
}

md-table-container td.md-cell.align-center, md-table-container th.md-column.center {
  text-align: center;
}

md-table-container td.md-cell.align-left, md-table-container th.md-column.align-left {
  text-align: left;
}

md-table-container td.md-cell.align-right, md-table-container th.md-column.align-right {
  text-align: right;
}

md-table-container .col-20 {
  width: 20%;
}

md-table-container a {
  font-weight: 700;
  color: #6e297a;
  text-decoration: none;
}

md-table-container a:hover {
  text-decoration: underline;
}

.main-header .md-toolbar-tools {
  height: 50px;
  max-height: 50px;
}

.main-header h1 {
  font-size: 24px;
  font-weight: 300;
}

.main-header p {
  margin: 0;
}

.main-header .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 10px;
}

.main-header .avatar img {
  width: 40px;
}

.main-header .md-icon-button {
  margin: 0 0.6rem;
  width: 3rem;
  height: 3rem;
  min-width: 0;
  line-height: 3rem;
  padding-left: 0;
  padding-right: 0;
}

.main-header .md-icon-button md-icon {
  font-size: 2rem;
}

md-sidenav.md-umbrella-theme.md-locked-open.md-sidenav-left {
  position: fixed;
}

.umb-sidenav-offset {
  margin-left: 320px;
}

.add-person h2 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.add-person p {
  margin: 0;
  padding-bottom: 1.5em;
}

.add-person md-radio-group > span {
  font-size: 13px;
  padding-bottom: 10px;
  color: #757575;
}

.add-person md-checkbox {
  padding: 0.5em 1.5em 0.5em 0;
  margin: 1em 1em 1em 0;
}

.add-person md-checkbox:first-of-type {
  padding-right: 30px;
  border-right: 1px solid #999;
}

.add-person label {
  display: block;
}

.list-toolbar md-input-container.md-umbrella-theme {
  margin: 0;
}

.list-toolbar .search-area input::-moz-placeholder {
  color: #fff !important;
}

.list md-table-container {
  border: none;
}

.list md-table-container h2 {
  margin: 20px 0 0;
  color: #6e297a;
}

profile-image {
  background-color: #fff;
  width: 170px;
  height: 170px;
  border: 5px solid #6e297a;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3), 0 10px 10px rgba(0, 0, 0, 0.1);
  margin: 1em 0;
}

profile-image svg {
  fill: #521f5b;
}

.fee-schedule-print-header {
  -webkit-print-color-adjust: exact;
  padding-bottom: 0;
}

@media print {
  .fee-schedule-print-header {
    display: table-header-group;
  }
}

.fee-schedule-print-header .payor-info {
  border: 1px solid rgba(110, 41, 122, 0.7);
}

.fee-schedule-print-header h2, .fee-schedule-print-header h3, .fee-schedule-print-header h4 {
  display: inline;
  font-weight: 700;
  padding: 0;
  margin: 0;
  padding-bottom: 0.2em;
}

.fee-schedule-print-header h2 span, .fee-schedule-print-header h3 span, .fee-schedule-print-header h4 span {
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 8px;
  padding-bottom: 2px;
  display: block;
  border-bottom: 1px dotted #6e297a;
  margin-bottom: 2px;
}

.fee-schedule-print-header h4 {
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  padding: 6px 0;
  font-weight: 700;
}

.fee-schedule-print-header h4:first-of-type {
  padding-top: 0px;
}

.fee-schedule-print-header h4:last-of-type {
  padding-bottom: 0px;
}

.fee-schedule-print-header .address, .fee-schedule-print-header .contact-details {
  margin-top: 0.4em;
  font-size: 80%;
  line-height: 1.3em;
  margin-bottom: 0;
}

.fee-schedule-print-header .column {
  padding: 20px;
}

.fee-schedule-print-header .border {
  background: rgba(110, 41, 122, 0.05);
  max-width: 40%;
  border-left: 1px solid rgba(110, 41, 122, 0.5);
}

.fee-schedule {
  padding-top: 0;
}

@media print {
  .fee-schedule {
    -webkit-print-color-adjust: exact;
    page-break-before: always;
    padding-top: 60px;
    padding-bottom: 15px;
  }
  .fee-schedule:first-of-type {
    padding-top: 0;
  }
}

.fee-schedule hr {
  opacity: 0;
}

.fee-schedule .cell {
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  line-height: 20px;
}

.fee-schedule .schedule-title {
  font-size: 18px;
  position: relative;
  width: auto;
  box-sizing: border-box;
  margin-bottom: 0;
  font-weight: 700;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: #6e297a;
  padding: 10px 24px 10px 16px;
  color: #fff;
}

.fee-schedule .schedule-title md-icon {
  font-weight: normal;
  color: #fff;
}

.fee-schedule .invoice-date {
  padding-left: 16px;
}

.fee-schedule .day-count {
  text-align: center;
}

.fee-schedule .fee-per-day {
  text-align: right;
}

.fee-schedule .sub-total {
  text-align: right;
  padding-right: 16px;
}

.fee-schedule .schedule-header {
  width: 100%;
  font-size: 12px;
  padding: 10px 0;
  font-weight: bold;
  border: 1px solid rgba(110, 41, 122, 0.7);
  background: rgba(110, 41, 122, 0.05);
}

.fee-schedule .schedule-header > * {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2em;
}

.fee-schedule .schedule-header .invoice-date {
  width: 140px;
}

.fee-schedule .schedule-header .child-name {
  width: 200px;
}

.fee-schedule .schedule-header .facility-name {
  width: 160px;
}

.fee-schedule .schedule-header .care-type-name {
  width: 220px;
}

.fee-schedule .schedule-header .day-count {
  width: 60px;
}

.fee-schedule .schedule-header .fee-per-day {
  width: 80px;
}

.fee-schedule .schedule-header .sub-total {
  width: 100px;
}

.fee-schedule .invoice-group {
  padding: 16px 0 0;
  border-bottom: 1px solid rgba(110, 41, 122, 0.7);
}

@media print {
  .fee-schedule .invoice-group {
    page-break-inside: avoid;
  }
}

.fee-schedule .invoice-group:last-of-type {
  border-bottom: 2px solid rgba(110, 41, 122, 0.7);
}

.fee-schedule .invoice-group .invoice-date {
  font-weight: bold;
  width: 140px;
}

.fee-schedule .invoice-group .children {
  width: 820px;
}

.fee-schedule .invoice-group .child-row {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.fee-schedule .invoice-group .child-row:last-of-type {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}

.fee-schedule .invoice-group .child-name {
  font-weight: bold;
  display: inline-block;
  float: left;
  width: 200px;
  vertical-align: top;
}

.fee-schedule .invoice-group .child-name .percentage {
  display: block;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1em;
}

.fee-schedule .invoice-group .facility {
  display: inline-block;
  width: 620px;
}

.fee-schedule .invoice-group .facility .facility-row {
  width: 100%;
  display: inline-block;
  padding-bottom: 8px;
}

.fee-schedule .invoice-group .facility .facility-row:last-of-type {
  padding-bottom: 0;
}

.fee-schedule .invoice-group .facility-name {
  display: inline-block;
  float: left;
  vertical-align: top;
  width: 160px;
  font-size: 70%;
  font-weight: bold;
  text-transform: uppercase;
}

.fee-schedule .invoice-group .care-type {
  float: left;
  clear: right;
  display: inline-block;
  vertical-align: top;
  width: 460px;
}

.fee-schedule .invoice-group .care-type .row {
  display: inline-block;
  width: 100%;
  margin-bottom: 0.25em;
}

.fee-schedule .invoice-group .care-type .row:last-of-type {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}

.fee-schedule .invoice-group .care-type .care-type-name, .fee-schedule .invoice-group .care-type .day-count, .fee-schedule .invoice-group .care-type .fee-per-day, .fee-schedule .invoice-group .care-type .sub-total {
  display: inline-block;
  float: left;
  vertical-align: middle;
  line-height: 20px;
}

.fee-schedule .invoice-group .care-type .care-type-name {
  width: 220px;
  font-size: 70%;
  font-weight: bold;
  text-transform: uppercase;
}

.fee-schedule .invoice-group .care-type .care-type-name .subsidized {
  display: block;
  font-weight: normal;
  font-size: 11px;
  line-height: 1em;
}

.fee-schedule .invoice-group .care-type .day-count {
  position: relative;
  width: 60px;
  text-align: center;
  font-size: 90%;
}

.fee-schedule .invoice-group .care-type .day-count:after {
  content: '@';
  position: absolute;
  right: -6px;
  top: -2px;
  line-height: 20px;
  font-size: 80%;
}

.fee-schedule .invoice-group .care-type .fee-per-day {
  width: 80px;
  font-size: 90%;
}

.fee-schedule .invoice-group .care-type .sub-total {
  font-size: 90%;
  width: 100px;
}

.fee-schedule .invoice-group-total {
  margin-top: 16px;
}

.fee-schedule .invoice-group-total .total-fees, .fee-schedule .invoice-group-total .total-fees-label {
  font-weight: bold;
  border-top: 3px solid rgba(110, 41, 122, 0.7);
  line-height: 40px;
}

.fee-schedule .invoice-group-total .total-fees {
  text-align: right;
  width: 100px;
  padding-right: 16px;
}

.fee-schedule .invoice-group-total .total-fees-label {
  font-size: 80%;
  text-transform: uppercase;
  padding: 0 26px;
  text-align: center;
}

.paymentInfo .payment-option, .paymentInfo .subsidy {
  padding-top: 32px;
  padding-bottom: 32px;
}

.paymentInfo .pap-form {
  padding-top: 20px;
}

.paymentInfo md-radio-button {
  margin: 0;
}

.additionalInfo md-checkbox {
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 0;
}

.additionalInfo section md-checkbox {
  margin-top: 16px;
}

.additionalInfo md-table-container {
  margin-top: 0;
}

.additionalInfo md-table-container md-checkbox {
  margin-left: 20px !important;
}

.additionalInfo button {
  margin-left: 25px;
}

.accountStatement {
  -webkit-print-color-adjust: exact;
}

@media print {
  .accountStatement {
    display: table-header-group;
  }
  .accountStatement button {
    display: none;
  }
}

.accountStatement .transaction-data {
  margin-top: 10px;
}

.accountStatement table.md-table.md-row-select th.md-column:first-child {
  padding-left: 15px;
}

.accountStatement table.md-table td.md-cell.md-checkbox-cell, .accountStatement table.md-table th.md-column.md-checkbox-cell {
  max-width: 20px;
}

.accountStatement table.md-table td.md-cell.id, .accountStatement table.md-table th.md-column.id {
  width: 60px;
}

.accountStatement table.md-table td.md-cell.label, .accountStatement table.md-table th.md-column.label {
  width: 350px;
}

.accountStatement table.md-table td.md-cell.post-date, .accountStatement table.md-table th.md-column.post-date {
  width: 80px;
}

.accountStatement table.md-table td.md-cell.balance, .accountStatement table.md-table th.md-column.balance {
  background: rgba(110, 41, 122, 0.1);
  width: 90px;
  border-left: 1px rgba(110, 41, 122, 0.5) solid;
  font-weight: 700;
}

.accountStatement table.md-table td.md-cell.facility-name, .accountStatement table.md-table th.md-column.facility-name {
  text-align: left;
  min-width: 140px;
}

.accountStatement table.md-table td.md-cell.product-name, .accountStatement table.md-table th.md-column.product-name {
  text-align: left;
  min-width: 250px;
}

.accountStatement table.md-table td.md-cell.qty, .accountStatement table.md-table th.md-column.qty {
  width: 30px;
  text-align: center;
}

.accountStatement table.md-table td.md-cell.unit-price, .accountStatement table.md-table th.md-column.unit-price {
  width: 80px;
}

.accountStatement table.md-table td.md-cell.sub-total, .accountStatement table.md-table th.md-column.sub-total {
  width: 50px;
}

.accountStatement table.md-table td.md-cell.subsidy, .accountStatement table.md-table th.md-column.subsidy {
  max-width: 50px;
  text-align: center;
}

.accountStatement table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), .accountStatement table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding-right: 30px;
}

.accountStatement table.md-table.md-row-select tbody.md-body > tr.md-row.md-selected,
.accountStatement .transaction-detail {
  background: #fffbf5;
}

.accountStatement .transaction-detail td.md-cell {
  border-top: 0;
  width: 0;
  font-size: 12px;
  text-align: right;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accountStatement .transaction-detail td.md-cell.unit-price, .accountStatement .transaction-detail td.md-cell.sub-total {
  font-weight: 700;
  font-size: 13px;
}

.accountStatement .transaction-detail .invoice-details-cell {
  padding-bottom: 15px !important;
}

.accountStatement .transaction-detail table.md-table thead.md-head > tr.md-row {
  height: 35px;
}

.accountStatement .transaction-detail table.md-table td.md-cell:last-child, .accountStatement .transaction-detail table.md-table th.md-column:last-child {
  padding-right: 15px;
}

.accountStatement .transaction-detail table.md-table tbody.md-body > tr.md-row, .accountStatement .transaction-detail table.md-table tfoot.md-foot > tr.md-row {
  height: 3em;
}

.accountStatement .invoice-details {
  width: 765px;
  background: #fff;
  border: 1px solid rgba(110, 41, 122, 0.4);
  display: inline-block;
  text-align: right;
  margin: 0px 0px 10px 80px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.transaction-dialog {
  width: 920px;
  max-width: 920px;
  max-height: 600px;
}

.transaction-dialog .md-padding {
  padding: 20px 20px 0;
}

.transaction-dialog .md-horz-padding {
  padding: 0 20px;
}

.transaction-dialog md-toolbar {
  padding-left: 20px;
  padding-right: 20px;
}

.transaction-dialog .md-toolbar-tools {
  height: auto;
  padding: 0;
}

.transaction-dialog .md-toolbar-tools h2 {
  font-size: 18px;
}

.transaction-dialog .progress-indication {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-grid;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
}

.transaction-dialog .progress-indication.ng-enter, .transaction-dialog .progress-indication.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}

.transaction-dialog .progress-indication.ng-enter, .transaction-dialog .progress-indication.ng-leave.ng-leave-active {
  opacity: 0;
}

.transaction-dialog .progress-indication.ng-leave, .transaction-dialog .progress-indication.ng-enter.ng-enter-active {
  opacity: 1;
}

.transaction-dialog md-select.md-table-select {
  line-height: 2.2;
}

.transaction-dialog md-dialog-content.transaction md-icon {
  vertical-align: baseline;
}

.transaction-dialog md-tabs md-content {
  background: rgba(0, 0, 0, 0.03);
}

.transaction-dialog md-dialog-actions {
  margin: 10px 10px;
}

.transaction-dialog .transaction {
  background: #fffbf5;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.transaction-dialog .transaction md-input-container.md-umbrella-theme:not(.md-input-invalid).md-input-has-value label,
.transaction-dialog .transaction md-input-container.md-input-has-placeholder label:not(.md-no-float) {
  transform: translate3d(0, 4px, 0) scale(0.75);
  color: #6e297a;
}

.transaction-dialog .transaction md-input-container {
  margin-bottom: 0;
}

.transaction-dialog .transaction input {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
}

.transaction-dialog .transaction md-input-container .md-input.ng-invalid.ng-dirty {
  padding-bottom: 18px;
}

.transaction-dialog .transaction .md-select-value, .transaction-dialog .transaction md-select:not([disabled]):focus .md-select-value {
  padding-top: 5px;
  padding-bottom: 5px;
}

.transaction-dialog .transaction .transaction_type {
  max-width: 22%;
}

.transaction-dialog .transaction .payment_amount {
  max-width: 16%;
  padding-right: 0;
}

.transaction-dialog .transaction .payment_amount input {
  border: 2px solid #6e297a;
  background: #fff;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  font-size: 20px;
}

.transaction-dialog td.md-cell, .transaction-dialog thead.md-head > tr.md-row th {
  padding: 0 10px !important;
}

.transaction-dialog tr:last-of-type td.md-cell {
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

.transaction-dialog table md-input-container .md-errors-spacer {
  display: none;
}

.transaction-dialog .md-table md-input-container.md-umbrella-theme {
  padding-right: 0;
}

.transaction-dialog table.md-table td.md-cell.facility, .transaction-dialog table.md-table th.md-column.facility, .transaction-dialog table.md-table tfoot > tr td.facility {
  width: 25%;
}

.transaction-dialog table.md-table td.md-cell.product, .transaction-dialog table.md-table th.md-column.product, .transaction-dialog table.md-table tfoot > tr td.product {
  width: 35%;
}

.transaction-dialog table.md-table td.md-cell.qty, .transaction-dialog table.md-table th.md-column.qty, .transaction-dialog table.md-table tfoot > tr td.qty {
  text-align: center;
  width: 8%;
}

.transaction-dialog table.md-table td.md-cell.qty input, .transaction-dialog table.md-table th.md-column.qty input, .transaction-dialog table.md-table tfoot > tr td.qty input {
  text-align: center;
}

.transaction-dialog table.md-table td.md-cell.unit-price, .transaction-dialog table.md-table th.md-column.unit-price, .transaction-dialog table.md-table tfoot > tr td.unit-price {
  width: 10%;
}

.transaction-dialog table.md-table td.md-cell.unit-price input, .transaction-dialog table.md-table th.md-column.unit-price input, .transaction-dialog table.md-table tfoot > tr td.unit-price input {
  text-align: right;
}

.transaction-dialog table.md-table td.md-cell.owing, .transaction-dialog table.md-table th.md-column.owing, .transaction-dialog table.md-table tfoot > tr td.owing {
  font-weight: bold;
}

.transaction-dialog table.md-table td.md-cell.amount-applied, .transaction-dialog table.md-table th.md-column.amount-applied, .transaction-dialog table.md-table tfoot > tr td.amount-applied {
  width: 14%;
  padding-left: 20px !important;
}

.transaction-dialog table.md-table td.md-cell.amount-applied input, .transaction-dialog table.md-table th.md-column.amount-applied input, .transaction-dialog table.md-table tfoot > tr td.amount-applied input {
  text-align: right;
}

.transaction-dialog table.md-table td.md-cell.sub-total, .transaction-dialog table.md-table th.md-column.sub-total, .transaction-dialog table.md-table tfoot > tr td.sub-total {
  width: 14%;
  font-weight: 700;
}

.transaction-dialog table.md-table td.md-cell.sub-total, .transaction-dialog table.md-table td.md-cell.invoice-total, .transaction-dialog table.md-table th.md-column.sub-total, .transaction-dialog table.md-table th.md-column.invoice-total, .transaction-dialog table.md-table tfoot > tr td.sub-total, .transaction-dialog table.md-table tfoot > tr td.invoice-total {
  text-align: right;
  padding-right: 15px !important;
}

.transaction-dialog table.md-table td.md-cell.invoice-total, .transaction-dialog table.md-table td.md-cell.invoice-total-label, .transaction-dialog table.md-table th.md-column.invoice-total, .transaction-dialog table.md-table th.md-column.invoice-total-label, .transaction-dialog table.md-table tfoot > tr td.invoice-total, .transaction-dialog table.md-table tfoot > tr td.invoice-total-label {
  text-align: right;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 700;
}

.transaction-dialog table.md-table td.md-cell.subsidy, .transaction-dialog table.md-table th.md-column.subsidy, .transaction-dialog table.md-table tfoot > tr td.subsidy {
  background: rgba(110, 41, 122, 0.1);
  width: 10%;
  padding: 0 !important;
  text-align: center !important;
}

.transaction-dialog table.md-table td.md-cell.actions, .transaction-dialog table.md-table th.md-column.actions, .transaction-dialog table.md-table tfoot > tr td.actions {
  width: 6% !important;
  padding: 0 !important;
}

.transaction-dialog .md-select-value > span:nth-child(1) > div > span {
  display: none;
}

.transaction-dialog .btn-add-product {
  margin-left: 0px;
  color: #6e297a;
}

.transaction-dialog .btn-add-product md-icon {
  color: #6e297a;
}

.enrollment .schedule-item {
  margin-bottom: 30px;
}

.enrollment h3.schedule-title {
  margin: 2em 0;
  padding-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.enrollment h3.schedule-title span {
  position: absolute;
  transform: translateY(-50%);
  background: #fff;
  padding-right: 1em;
}

.enrollment p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.enrollment .quick-selection md-input-container {
  margin: 0 1em 0 0;
}

.enrollment .quick-selection md-input-container > span {
  display: block;
  font-size: 12px;
  padding-bottom: 8px;
}

.enrollment .quick-selection md-checkbox {
  margin-right: 1.1em;
}

.enrollment .quick-selection md-checkbox .md-label {
  font-size: 14px;
}

.enrollment .legend {
  margin: 1.2em 0 1.5em;
}

.enrollment .legend .item {
  margin-right: 24px;
}

.enrollment .legend .full-time {
  padding-right: 1.5em;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.enrollment .legend i {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 7px;
}

.enrollment .legend label {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  opacity: 0.6;
}

.enrollment .calendar, .enrollment .special-days {
  display: inline-block;
  margin: 0;
}

.enrollment .calendar md-card, .enrollment .special-days md-card {
  margin: 0 0 1em 0;
  padding: 0px 0 0 12px;
}

.enrollment .calendar {
  display: block;
  position: relative;
  margin-left: 164px;
  margin-right: 40px;
}

.enrollment .calendar .override-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 65px;
  background: rgba(0, 0, 0, 0.05);
}

.enrollment .calendar .md-button {
  min-height: 40px;
  min-width: 40px;
  margin: 4px !important;
  font-size: 16px;
  font-weight: normal;
}

.enrollment .calendar .md-button:hover {
  background-color: #9738a8;
  opacity: .5;
  color: #fff;
}

.enrollment .calendar .md-button[disabled], .enrollment .calendar .available.paday.not-available, .enrollment .calendar .available.schoolbreak.not-available {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

.enrollment .calendar .md-button .day-of-month {
  position: relative;
  z-index: 5;
}

.enrollment .calendar md-card-header {
  padding: 0;
  border-bottom: 2px solid rgba(106, 27, 154, 0.4);
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.enrollment .calendar md-card-header .invoice-date {
  line-height: 3.6em;
  border-bottom: 2px solid rgba(106, 27, 154, 0.4);
}

.enrollment .calendar md-card-header .day-of-week {
  min-width: 40px;
  margin: 0 4px;
  line-height: 3.6em;
}

.enrollment .calendar md-card-header .ft-override {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.enrollment .calendar md-card-actions {
  margin: 0;
  padding: 8px 0;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.enrollment .calendar md-card-actions .highlighted {
  background-color: rgba(0, 0, 0, 0.2);
}

.enrollment .calendar md-card-actions:last-of-type {
  border-bottom: 0;
}

.enrollment .calendar md-card-actions .invoice-date {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -8px;
}

.enrollment .calendar md-card-actions .invoice-date span {
  display: block;
  margin-top: 8px;
}

.enrollment .calendar md-card-actions .invoice-date md-icon {
  min-width: auto;
  min-height: auto;
  font-size: 1em;
  vertical-align: top;
}

.enrollment .calendar md-card-actions .ft-override {
  width: 65px;
  margin: 0;
  padding: 0;
}

.enrollment .calendar md-card-actions .ft-override md-checkbox {
  margin: 0;
  left: 4px;
}

.enrollment .calendar md-card-actions .ft-override md-checkbox[disabled] {
  opacity: 0;
}

.enrollment .calendar md-card-actions.invoiced {
  opacity: 0.4;
}

.enrollment .calendar .invoice-date {
  text-align: left;
  width: 152px;
  position: absolute;
  transform: translateX(-176px);
}

.enrollment .calendar .invoice-date i {
  display: block;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.enrollment .calendar .ft-override {
  text-align: center;
  width: 65px;
  margin-left: 4px !important;
}

.enrollment .special-days {
  min-width: 380px;
}

.enrollment .special-days md-card-header {
  padding: 0;
  font-size: 12px;
  margin-bottom: 10px;
}

.enrollment .special-days .md-title {
  font-size: 18px;
}

.enrollment .special-days md-card {
  padding: 5px 14.4px;
}

.enrollment .special-days md-checkbox {
  margin-top: 7px;
}

.enrollment .special-days md-input-container {
  margin-top: 0;
  margin-right: 0;
}

.enrollment .selected {
  border-radius: 40px !important;
  color: #ffffff;
  background-color: #6e297a;
  font-weight: bold !important;
}

.enrollment .selected.full-time-rate {
  box-shadow: 0 0 0px 5px #ea80fc;
}

.enrollment .closed {
  background-color: #cccccc;
}

.enrollment .closed.selected {
  color: inherit;
  background-color: #cccccc;
}

.enrollment .fullday.selected:not(.closed) {
  background-color: #740088;
  color: #ffffff;
}

.enrollment .available.before, .enrollment .available.after {
  position: relative;
  overflow: hidden;
}

.enrollment .available.before:after, .enrollment .available.after:after {
  content: "";
  backface-visibility: hidden;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 50px 50px 0 0;
  transition: transform 0.25s ease;
}

.enrollment .available.before:after {
  background-color: #4db4dc;
}

.enrollment .available.after:after {
  background-color: #d7b928;
}

.enrollment .available.before::after, .enrollment .available.closedbefore::after {
  transform: rotate(135deg);
  transform-origin: bottom;
}

.enrollment .available.before::after span, .enrollment .available.closedbefore::after span {
  transform: rotate(-135deg);
  display: block;
}

.enrollment .available.after::after, .enrollment .available.closedafter::after {
  transform: rotate(-45deg);
  transform-origin: bottom;
}

.enrollment .available.after::after span, .enrollment .available.closedafter::after span {
  transform: rotate(45deg);
  display: block;
}

.enrollment .available.paday, .enrollment .available.paday[disabled] {
  background-color: #69be69;
  color: #ffffff;
  opacity: 1;
}

.enrollment .available.schoolbreak, .enrollment .available.schoolbreak[disabled] {
  background-color: #ffa235;
  color: #ffffff;
  opacity: 1;
}

.fee-splitting .cell-padding, .fee-splitting .by-day-selection.total-fees md-input-container {
  padding: 0 12px;
}

.fee-splitting .total-fees {
  width: 100px;
}

.fee-splitting .payors {
  cursor: pointer;
  width: 200px;
}

.fee-splitting .payors md-input-container {
  font-weight: 700;
  line-height: 26px;
}

.fee-splitting .percentage {
  width: 100px;
  text-align: center;
}

.fee-splitting .percentage .md-errors-spacer {
  display: none;
}

.fee-splitting .percentage md-input-container {
  padding-left: 0;
  padding-right: 0;
}

.fee-splitting .percentage md-input-container input {
  text-align: right;
}

.fee-splitting .total-fees {
  width: 100px;
  font-weight: 700;
  text-align: right;
}

.fee-splitting .total-fees > * {
  padding-right: 0;
  line-height: 30px;
}

.fee-splitting .invoice-group {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}

.fee-splitting .invoice-group.first {
  border-top: 0;
}

.fee-splitting .payor-row.payor-0 {
  background-color: rgba(110, 41, 122, 0.17);
}

.fee-splitting .payor-0 {
  background-color: #6e297a;
}

.fee-splitting .payor-row.payor-1 {
  background-color: rgba(7, 116, 145, 0.17);
}

.fee-splitting .payor-1 {
  background-color: #077491;
}

.fee-splitting .payor-row.payor-2 {
  background-color: rgba(0, 182, 120, 0.17);
}

.fee-splitting .payor-2 {
  background-color: #00B678;
}

.fee-splitting .payor-row.payor-3 {
  background-color: rgba(195, 137, 0, 0.17);
}

.fee-splitting .payor-3 {
  background-color: #C38900;
}

.fee-splitting .by-day-selection .period-covered {
  width: 464px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.fee-splitting .by-day-selection .payor-row {
  overflow: hidden;
}

.fee-splitting .by-day-selection .payor-row:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.fee-splitting .by-day-selection.total-fees md-input-container {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 109px;
  line-height: 109px;
}

.fee-splitting .by-day-selection .calendar .md-button.day {
  opacity: 0.35;
  transition: opacity 0.2s ease-in-out;
}

.fee-splitting .by-day-selection .calendar .md-button.day:hover {
  opacity: 1;
}

.fee-splitting .by-day-selection .calendar .md-button.day.assigned {
  opacity: 1;
}

.fee-splitting .by-percentage-selection {
  min-height: 160px;
}

.fee-splitting .by-percentage-selection .calendar .md-button.day {
  cursor: not-allowed;
}

.fee-splitting .by-percentage-selection .calendar .md-button.day:hover {
  opacity: 1;
}

.fee-splitting .calendar .md-button.day[disabled]:hover, .fee-splitting .calendar .umb-calendar .day.available.paday.not-available:hover, .umb-calendar .fee-splitting .calendar .day.available.paday.not-available:hover, .fee-splitting .calendar .umb-calendar .day.available.schoolbreak.not-available:hover, .umb-calendar .fee-splitting .calendar .day.available.schoolbreak.not-available:hover, .fee-splitting .calendar .enrollment .day.available.paday.not-available:hover, .enrollment .fee-splitting .calendar .day.available.paday.not-available:hover, .fee-splitting .calendar .enrollment .day.available.schoolbreak.not-available:hover, .enrollment .fee-splitting .calendar .day.available.schoolbreak.not-available:hover {
  background-color: transparent;
  opacity: 0.5;
  color: inherit;
  cursor: not-allowed;
}

.fee-splitting .block .total-fees {
  font-size: 20px;
}

.fee-splitting .block.invoiced {
  opacity: 0.4;
}

.fee-splitting2 .schedule {
  margin-bottom: 30px;
}

.fee-splitting2 .schedule:last-of-type {
  margin-bottom: 0;
}

.fee-splitting2 .schedule h3 {
  margin: 1em 0;
  padding-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.fee-splitting2 .schedule h3 span {
  position: absolute;
  transform: translateY(-50%);
  background: #fff;
  padding-right: 1em;
}

.fee-splitting2 .cell-padding, .fee-splitting2 .fee-splitting .by-day-selection.total-fees md-input-container, .fee-splitting .by-day-selection.total-fees .fee-splitting2 md-input-container {
  padding: 0 12px;
}

.fee-splitting2 .invoice-group {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}

.fee-splitting2 .invoice-group.first {
  border-top: 0;
}

.fee-splitting2 .invoice-date {
  min-width: 170px;
}

.fee-splitting2 .invoice-date i {
  display: block;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.fee-splitting2 .umb-calendar md-card {
  box-shadow: none;
}

.fee-splitting2 .ft-override {
  text-align: center;
  min-width: 80px;
}

.fee-splitting2 .ft-override md-input-container {
  text-align: center;
  padding: 0;
  margin: 5px;
  min-height: 40px;
  min-width: 40px;
}

.fee-splitting2 .ft-override md-input-container md-checkbox {
  min-height: 40px;
  min-width: 40px;
  margin-bottom: 0;
}

.fee-splitting2 .ft-override md-input-container md-checkbox .md-container {
  left: 25%;
}

.fee-splitting2 .fee-per-day {
  text-align: right;
  min-width: 100px;
}

.fee-splitting2 .payors .name {
  line-height: 30px;
}

.fee-splitting2 .percentage .md-errors-spacer {
  display: none;
}

.fee-splitting2 .total-fees {
  font-weight: 700;
  text-align: right;
}

.fee-splitting2 .total-fees > * {
  padding-right: 0;
  line-height: 30px;
}

.associations md-autocomplete {
  height: 58px;
}

.associations strong {
  margin-right: 20px;
}

.associations md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: 32px;
}

.profile-page .profile {
  padding-bottom: 20px;
}
