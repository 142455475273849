.fee-schedule-print-header {
	$purple: #6e297a;

	-webkit-print-color-adjust: exact;
	//display: none;

	@media print {
		display: table-header-group;
		
	}
	
	img.logo {
		//max-width: 40%;
	}

	.payor-info {
		border: 1px solid rgba($purple, 0.7);
	}

	h2, h3, h4 {
		display: inline;
		font-weight: 700;
		span {
			width: 100%;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 8px;
			padding-bottom: 2px;
			display: block;
			border-bottom: 1px dotted $purple;
			margin-bottom: 2px;
		}
		padding: 0;
		margin: 0;
		padding-bottom: 0.2em;
	}

	h4 {
		text-transform: uppercase;
		font-size: 12px;
		margin: 0;
		padding: 6px 0;
		font-weight: 700;

		&:first-of-type {
			padding-top: 0px;
		}

		&:last-of-type {
			padding-bottom: 0px;
		}
	}

	.address, .contact-details {
		margin-top: 0.4em;
		font-size: 80%;
		line-height: 1.3em;
		margin-bottom: 0;
	}

	.column {
		padding: 20px;
	}

	.border {
		background: rgba($purple, 0.05);
		max-width: 40%;
		border-left: 1px solid rgba($purple,0.5);
	}

	padding-bottom: 0;

}

.fee-schedule {
	$maxWidth: 960px;

	$invoiceDateWidth: 140px;
	$childNameWidth: 200px;
	$facilityNameWidth: 160px;
	$careTypeNameWidth: 220px;
	$dayCountWidth: 60px;
	$feePerDayWidth: 80px;
	$subTotalWidth: 100px;

	$vertPadding: 16px;
	$cellPadding: 8px;

	$purple: #6e297a;
	
	@media print {
		-webkit-print-color-adjust: exact;
		page-break-before: always;

		padding-top: 60px;
		padding-bottom: 15px;
		
		&:first-of-type {
			padding-top: 0;
		}

	}

	hr {
		opacity: 0;
		//page-break-before: always;
	}

	padding-top: 0;

	.cell {
		padding-left: $cellPadding;
		padding-right: $cellPadding;
		box-sizing: border-box;
		line-height: 20px;
	}
	.schedule-title {
		font-size: 18px;
		position: relative;
		width: auto;
		box-sizing: border-box;
		margin-bottom: 0;
		font-weight: 700;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;;
		background: $purple;
		padding: 10px $cellPadding * 3 10px $cellPadding * 2;
		color:#fff;

		md-icon {
			font-weight: normal;
			color:#fff;
		}
	}

	.invoice-date {
		padding-left: $cellPadding * 2;
	}

	.day-count {
		text-align: center;
	}

	.fee-per-day {
		text-align: right;
	}

	.sub-total {
		text-align: right;
		padding-right: $cellPadding * 2;
	}
	
	.schedule-header {
		width: 100%;
		font-size: 12px;
		
		padding: 10px 0;
		font-weight: bold;
		border: 1px solid rgba($purple, 0.7);
		background: rgba($purple, 0.05);

		> * {
			display: inline-block;
			vertical-align: middle;
			line-height: 1.2em;
		}

		.invoice-date { width: $invoiceDateWidth; }
		.child-name { width: $childNameWidth; }
		.facility-name { width: $facilityNameWidth; }
		.care-type-name { width: $careTypeNameWidth; }
		.day-count { width: $dayCountWidth; }
		.fee-per-day { width: $feePerDayWidth; }
		.sub-total { width: $subTotalWidth; }
	}

	.invoice-group {
		@media print {
			page-break-inside: avoid;
		}

		padding: $vertPadding 0 0;
		border-bottom: 1px solid rgba($purple, 0.7);


		&:last-of-type {
			border-bottom: 2px solid rgba($purple, 0.7);
		}

		.invoice-date {
			font-weight: bold;
			width: $invoiceDateWidth;
		}

		.children {
			width: $maxWidth - $invoiceDateWidth;
			//width: $childNameWidth + $facilityNameWidth + $careTypeNameWidth + $dayCountWidth + $feePerDayWidth + $subTotalWidth;
		}

		.child-row {
			//width:  $childNameWidth + $facilityNameWidth + $careTypeNameWidth + $dayCountWidth + $feePerDayWidth + $subTotalWidth;
			border-bottom: 1px dotted rgba(0,0,0,0.2);
			padding-bottom: $vertPadding / 2;
			margin-bottom: $vertPadding;

			&:last-of-type {
				margin-bottom: 0;
				border: none;
				padding-bottom: 0;
			}
		}

		.child-name {
			font-weight: bold;
			display: inline-block;
			float: left;
			width: $childNameWidth;
			vertical-align: top;

			.percentage {
				display: block;
				font-weight: normal;
				text-transform: uppercase;
				font-size: 11px;
				line-height: 1em;
			}
			
		}

		.facility {
			//float: left;
			display: inline-block;
			//width: 100%;
			width: $maxWidth - $invoiceDateWidth - $childNameWidth;

			.facility-row {
				width: 100%;
				display: inline-block;
				padding-bottom: $vertPadding / 2;

				&:last-of-type { padding-bottom: 0; }
			}

		}

		.facility-name {
			display: inline-block;
			float: left;
			vertical-align: top;
			width: $facilityNameWidth;
			font-size: 70%;
			font-weight: bold;
			text-transform: uppercase;
		}

		.care-type {
			float: left;
			clear: right;
			display: inline-block;
			vertical-align: top;
			width: $maxWidth - $invoiceDateWidth - $childNameWidth - $facilityNameWidth;

			.row {
				display: inline-block;
				width: 100%;
				margin-bottom: 0.25em;

				&:last-of-type {
					margin-bottom: 0;
					border: none;
					padding-bottom: 0;
				}
			}

			.care-type-name, .day-count, .fee-per-day, .sub-total {
				display: inline-block;
				float: left;
				vertical-align: middle;
				line-height: 20px;
			}

			.care-type-name {
				width: $careTypeNameWidth;
				
				font-size: 70%;
				font-weight: bold;
				text-transform: uppercase;

				.subsidized {
					display: block;
					font-weight: normal;
					font-size: 11px;
					line-height: 1em;
				}
			}

			.day-count {
				position: relative;
				width: $dayCountWidth;
				text-align: center;
				font-size: 90%;

				&:after {
					content: '@';
					position: absolute;
					right: -6px;
					top: -2px;
					line-height: 20px;
					font-size: 80%;
				}
			}

			.fee-per-day {
				width: $feePerDayWidth;
				font-size: 90%;
			}

			.sub-total {
				font-size: 90%;
				//font-weight: bold;
				width: $subTotalWidth;
			}
		}


	}

	.invoice-group-total {
		margin-top: $vertPadding;

		.total-fees, .total-fees-label {
			font-weight: bold;
			border-top: 3px solid rgba($purple, 0.7);
			//border-right: 1px solid rgba($purple, 0.7);
			line-height: 40px;
			//background: rgba($purple,0.08);
		}

		.total-fees {
			text-align: right;
			width: $subTotalWidth;
			padding-right: $cellPadding * 2;
		}
		.total-fees-label {
			//background: $purple;
			//color: #fff;
			font-size: 80%;
			text-transform: uppercase;
			padding: 0 26px;
			text-align: center;
			//width: $dayCountWidth + $feePerDayWidth;
		}
	}



}