.paymentInfo {

	.payment-option, .subsidy{
		padding-top: 32px;
		padding-bottom: 32px;
	}

	.pap-form {
		padding-top: 20px;
	}
	
	md-radio-button {
		margin: 0;
		h2 {

		}
	}

}