md-tabs.umb-person-tabs {
	border-radius: 0;
	background-color: rgb(151,56,168);
    color: rgba(255,255,255,0.87);

    .md-tab {
    	cursor: pointer;
      color: rgba(255, 255, 255, 0.5);

    	&.md-active {
    		color: #fff;
    		background: lighten(rgb(151,56,168), 5%);
   		}
   		&[disabled] {
   			cursor: not-allowed;
   			color: #fff;
   		}
   	}

   	md-ink-bar {
   		height: 4px;
   		color: lighten(rgb(151,56,168), 30%);
   		background: lighten(rgb(151,56,168), 30%);
   	}

}