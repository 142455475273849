.fee-splitting {
	
	$totalFeesColumnWidth: 100px!default;
	$payorsColumnWidth: 200px!default;
	$percentageColumnWidth: 100px!default;
	$payorTotalColumnWidth: 100px!default;
	
	//Colours for payors
	$max-payors: 10;
	$payor-colours: (
		payor-0: $umbPurple,
		payor-1: #077491,
		payor-2: #00B678,
		payor-3: #C38900
	);
	
	
	@mixin payor-colours {
		@each $key, $val in $payor-colours {
			.payor-row.#{$key} { 
				background-color: rgba($val, 0.17); 
			}

			.#{$key} {
				background-color: $val;
			}
		}
	}

	//Class Definitions
	.cell-padding {
		padding: 0 12px;
	}

	.total-fees, .payors, .percentage, .total-fees {
		//display: table-column;
	}
	.total-fees {
		width: $totalFeesColumnWidth;
	}

	.payors {
		cursor: pointer;
		width: $payorsColumnWidth;

		md-input-container {
			font-weight: 700;
			line-height: 26px;
		}
	}

	.percentage {
		width: $percentageColumnWidth;
		text-align: center;

		.md-errors-spacer { display: none; }

		md-input-container {
			padding-left: 0;
			padding-right: 0;
			
			input {
				text-align: right;
			}
		}
	}

	.total-fees {
		width: $payorTotalColumnWidth;
		font-weight: 700;
		text-align: right;
		
		> * {
			//text-align: right;
			padding-right: 0;
			line-height: 30px;
		}
	}

	.invoice-group {
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0;

		&.first {
			border-top: 0;
		}
	}
	
	//Setup the payor colours
	@include payor-colours;

	.by-day-selection {
		.period-covered {
			width: $payorsColumnWidth + $calendarWidth;
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
		}

		.payor-row {
			overflow: hidden;
		}

		.payor-row:not(:first-of-type) {
			border-top: 1px solid rgba(0,0,0,0.1);
		}

		&.total-fees md-input-container {
			margin-top: 0;
			margin-bottom: 0;
			min-height: ($dateWidth * 2) + ($dateSpacing * 4) + ($cardPadding) + 1;
			line-height: ($dateWidth * 2) + ($dateSpacing * 4) + ($cardPadding) + 1;
			@extend .cell-padding;
		}

		.calendar .md-button.day { 
			opacity: 0.35;
			transition: opacity 0.2s ease-in-out;

			&:hover {
				opacity: 1;
			}

			&.assigned {
				opacity: 1;
			}
		}
	}

	.by-percentage-selection {
		min-height: 160px;
		.calendar .md-button.day { cursor: not-allowed; }
		.calendar .md-button.day:hover { opacity: 1;}
	}
	
	.calendar .md-button.day[disabled]:hover {
		background-color: transparent;
		opacity: 0.5;
		color: inherit;
		cursor: not-allowed;
		// @extend .md-button[disabled];
	}

	.block {
		.total-fees {
			font-size: 20px;
		}

		&.invoiced { opacity: 0.4; }
	}
	

}



///PLEASE REFACTOR!!!
.fee-splitting2 {
	//Colors for users
	$user0: black;
	$user1: yellow;
	$user2: aqua;
	$user3: brown;
	$user4: red;
	$user5: blue;
	$user6: orange;
	$user7: green;
	$user8: magenta;
	$user9: darkgray;
	

	.schedule {
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0;
		}

		h3 {
			margin: 1em 0;
			padding-top: 0;
			border-top: 1px solid rgba(0, 0, 0, 0.2);
			
			span {
				position: absolute;
				transform: translateY(-50%);
				background: #fff;
				padding-right: 1em;
			}
		}
	}

	.cell-padding {
		padding: 0 12px;
	}

	.invoice-group {
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0;

		&.first {
			border-top: 0;
		}
	}

	.invoice-date {
		min-width: $invoiceDateWidth - $cardPadding;

		i {
			display: block;
			font-style: normal;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
		}

	}

	.umb-calendar {
		
		md-card {
			//background: transparent;
			box-shadow: none;
		}
	}


	.ft-override {
		text-align: center;
		min-width: $dateWidth * 2;
		
		md-input-container {
			text-align: center;
			padding: 0;
			margin: 5px;
			min-height: $dateWidth;
			min-width: $dateWidth;

			md-checkbox {
				min-height: $dateWidth;
				min-width: $dateWidth;
				margin-bottom: 0;

				.md-container {
					left:25%;
				}
			}
		}

	}

	.fee-per-day {
		text-align: right;
		min-width: 100px;
	}

	.payors {
		//min-width: 180px;
		.name {
			line-height: 30px;
		}
	}

	.percentage {
		//width: 90px;
		.md-errors-spacer { display: none; }
	}

	.total-fees {
		//padding: 0 10px;
		font-weight: 700;
			text-align: right;
		
		> * {
			padding-right: 0;
			line-height: 30px;
		}
		// min-width: 100px;
	}

}

