// -------------------------------------------------------------------------- *\
//	Helper Classes
// -------------------------------------------------------------------------- */

// .float-left { float: left; }
// .float-right { float: right; }

// .pull-right { float: right!important;}

// .align-right { text-align: right; }
// .align-left { text-align: left; }

.center { text-align: center; }
// .caps, .uppercase { text-transform: uppercase; }
.nowrap { white-space: nowrap; display: block;}

// .round {
// 	border-radius: 50%;
// }


//Responsive Images
// img {
// 	vertical-align: middle;
// 	border-style: none;
// 	outline: none;
// 	width: 100%;
// 	height: auto;
// }

hr {
	margin-bottom: 30px;
}
