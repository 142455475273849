md-table-container {
    .md-row-select {
        cursor: pointer;
    }
	th {
		border-bottom: 2px solid purple;
	}

	&.no-checkbox {

	    .md-checkbox-column, .md-checkbox-cell {
	        display: none;
	    }

	}

	tr.deleted {
		text-decoration: line-through;

		.md-cell {
			color: rgba(0, 0, 0, 0.4);
		}
	}

	.action-link {
		cursor: pointer;
		display: block;
		z-index: 10;
		position: relative;
		background: url('../assets/img/blank.png');
		padding: 10px;
		white-space: nowrap;
		color: #6e297a;
		text-transform: uppercase;
		font-weight: 500;

		&:hover {
			text-decoration: none;
			opacity: 0.8;
		}
	}
	
	.md-cell {
		line-height: 1.4em;
	}
	td.md-cell.center, th.md-column.center {
		text-align: center;
	}
	td.md-cell.align-center, th.md-column.center{
		text-align: center;
	}

	td.md-cell.align-left, th.md-column.align-left{
		text-align: left;
	}

	td.md-cell.align-right, th.md-column.align-right{
		text-align: right;
	}

	.col-20 {
		width: 20%;
	}

	a {
		font-weight: 700;
		color: #6e297a;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}


}