.main-header {
	
	.md-toolbar-tools {
		height: 50px;
		max-height: 50px;
	}
	h1 {
		font-size: 24px;
		font-weight: 300;
	}

	p {
		margin: 0;
	}

	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		margin-left: 10px;

		img {
			width: 40px;
		}
	}

	.md-icon-button {
		margin: 0 0.6rem;
		width: 3rem;
		height: 3rem;
		min-width: 0;
		line-height: 3rem;
		padding-left: 0;
		padding-right: 0;

		md-icon {
			font-size: 2rem;
		}
		
	}

}