md-toolbar {
    padding: 6px 8px;
    min-height: 0;
}

md-toolbar md-input-container {
    font-size: 16px;
    color: #fff;
}

.list-toolbar {

	.add-button {
		position: absolute;
		transform: translateY(-15%);

		md-icon {
			color: rgb(110,41,122)!important;
		}
	}

	md-select.md-umbrella-theme .md-select-value {
		border-color: transparent;
	}
	
	md-input-container.md-umbrella-theme {
		margin: 0;

		input {
			color: #fff!important;

			&:input-placeholder {
		    	color: rgba(255, 255, 255, 0.8);
		    }

		    &::-webkit-input-placeholder {
				color: rgba(255, 255, 255, 0.8);
			}
			&:-moz-placeholder{
				color: rgba(255, 255, 255, 0.8);
			}
			&:-ms-input-placeholder {
				color: rgba(255, 255, 255, 0.8);
			}
		}
		
		.roleFilter {
	    	margin-left: 10%;
	    }

	    .search {
		    // width: 250px;
		}

		.statusFilter {
		    margin-left: 20%;
		}

		.md-input, &.md-input-focused .md-focus {
			font-size: 120%;
			border-color: transparent!important;
		}

	}

}