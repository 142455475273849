profile-image {
	
	background-color: #fff;
	width: 170px;
	height: 170px;
	border: 5px solid $umbPurple;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 2px 2px rgba(0,0,0,0.3), 0 10px 10px rgba(0,0,0,0.1);
	margin: 1em 0;

	svg {
		fill: darken($umbPurple, 8%);
	}

}