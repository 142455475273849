.list-toolbar {
	md-input-container.md-umbrella-theme {
		margin: 0;
	}

	.search-area input {
		//background: red;
		&::-moz-placeholder {
			color: #fff!important;
		}
	}
}

.list {
	md-table-container {
		border: none;

		h2 {
			margin: 20px 0 0;
			color: rgb(110, 41, 122);

		}
	}


	.results {


	
	}

}



	