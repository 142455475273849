.add-person {

	h2 {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	p {
		margin: 0;
		padding-bottom: 1.5em;
	}

	md-radio-group > span {
		font-size: 13px;
		padding-bottom: 10px;
		color: #757575;
	}

	md-checkbox {
		padding: 0.5em 1.5em 0.5em 0;
		margin: 1em 1em 1em 0;

		&:first-of-type {
			padding-right: 30px;
			border-right: 1px solid #999;
		}
	}

	label {
		display: block;
	}

	

}
