table {
	th.md-column, td.md-cell {

		&:last-of-type {
			width: 15%;
			text-align: center;
		}

		md-icon {
			color: #6e297a;
		}
	}

	// td.md-cell:last-of-type {
	// 	text-transform: uppercase;
	// 	font-weight: 500;
	// 	color: #6e297a;
	// }
}

md-table-container {
	padding-bottom: 20px;
	margin-bottom: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.md-table thead.md-head > tr.md-row {
	height: 45px;
}
table.md-table th.md-column {
	color: rgba(0, 0, 0, 0.8)
}