.md-display-1{
	line-height: 0;
	padding-bottom: 10px;
}

h2 {
	margin-top: 0;
	font-size: 1.4em;

	.material-icons {
		color: #6e297a;
		font-size: 1.4em
	}
}

h3, h4 {
	display: inline-block;
	width: 100%;
}

// h3 {
// 	margin-bottom: 1em;
// }
h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 10px 0;
	color: #000000;
	text-align: left;
}


h4 {
	margin-bottom: 1em;
}

p {
	line-height: 1.6em;
	margin-top: 0;
	margin-bottom: 1em;

	// &:last-of-type {
	// 	margin-bottom: 0;
	// }
}

type, .type {
	color: #6e297a;
	font-size: 70%;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 0;
	text-transform: uppercase;
}