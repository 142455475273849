.associations {

  md-autocomplete {
	height: 58px;
  }

  strong {
	margin-right: 20px;
  }

  md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
	bottom: 32px;
  }
}