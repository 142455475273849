.umb-primary {
    color: #FFF!important;
    background-color: rgb(110,41,122);
    padding: 0px 20px;

    &.small {
        min-height: 22px;
        line-height: 27px;
        font-size: 11px;
    }
}

.umb-primary:hover {
    background-color: rgba(110,41,122,0.5) !important;
}

// .md-button.md-umbrella-theme.md-fab {
//     position: absolute;
//     padding-top: 8px;
//     top: 20px;
// }

.md-button.md-umbrella-theme.md-fab.add-button {
    background-color: rgba(250,250,250,1) !important;
    color: #333 !important;
}

