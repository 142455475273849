.additionalInfo {
  md-checkbox {
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 0;
  }

  section {
    md-checkbox {
      margin-top: 16px;
    }
  }

  md-table-container {
    margin-top: 0;

    md-checkbox {
      margin-left: 20px !important;
    }
  }

  button {
    margin-left: 25px;
  }
}