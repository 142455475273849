.enrollment {

	$cardMargin: 40px;
	$cardPadding: 12px;
	$dateWidth: 40px;
	$dateSpacing: 4px;
	$invoiceDateWidth: 164px;
	$ftOverrideWidth: 65px;
	$calendarWidth: ($dateWidth * 5) + ($dateSpacing * 2) * 5 + ($cardPadding * 2);

	$fullday: rgba(106, 27, 154, 1)!default;
	$beforeSchool: rgba(77, 180, 220, 1)!default;
	$afterSchool: rgba(215, 185, 40, 1)!default;
	$schoolbreak: #ffa235;
	$paday: #69be69;
	$closed: #cccccc;
	$fulltimeRate: rgba(234, 128, 252, 1)!default;
	

// 	$fullday: rgba(106, 27, 154, 1)!default;
// $beforeSchool: rgba(77, 180, 220, 1)!default;
// $afterSchool: rgba(215, 185, 40, 1)!default;
// $schoolbreak: #ffa235 !default;
// $paday: #69be69 !default;
// $closed: #cccccc !default;
// $fulltimeRate: rgba(234, 128, 252, 1)!default;

	
	.schedule-item{
		margin-bottom: 30px;
	}

	//Headings
	h3.schedule-title {
		margin: 2em 0;
		padding-top: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		
		span {
			position: absolute;
			transform: translateY(-50%);
			background: #fff;
			padding-right: 1em;
		}
	}

	p {
		margin-top: 0;
		margin-bottom: 1.5em;
	}

	//Quick Selection
	.quick-selection {
		md-input-container {
			margin: 0 1em 0 0;

			> span {
				display: block;
				font-size: 12px;
				padding-bottom: 8px;
			}
		}

		md-checkbox {
			margin-right: 1.1em;

			.md-label {
				font-size: 14px;
			}
		}
	}
	

	.legend {
		margin: 1.2em 0 1.5em;

		.item {
			margin-right: 24px;
		}

		.full-time {
			padding-right: 1.5em;
			border-right: 1px solid rgba(0,0,0,0.3);
		}

		i {
			height: 15px;
			width: 15px;
			vertical-align: middle;
			display: inline-block;
			margin-right: 7px;
		}

		label {
			display: inline-block;
			vertical-align: middle;
			font-size: 13px;
			opacity: 0.6;		
		}
		
	}


	.calendar, .special-days {
		display: inline-block;
		//color: #6E297A;
		margin: 0;
		
		md-card {
			margin: 0 0 1em 0;
			padding: 0px 0 0 $cardPadding
		}
	}

	.calendar {
		display: block;

		position: relative;
		//width: $calendarWidth + $ftOverrideWidth - $cardPadding;//265px;
		margin-left: $invoiceDateWidth;
		margin-right: $cardMargin;

		.override-bg {
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			height: 100%;
			width: $ftOverrideWidth;
			background: rgba(0,0,0,0.05);
		}
		
		.md-button {
			min-height: $dateWidth;
			min-width: $dateWidth;
			margin: $dateSpacing!important;
			font-size: 16px;
			font-weight: normal;

			&:hover {
				background-color: rgb(151,56,168);
				opacity: .5;
				color: #fff;
			}

			&[disabled] {
				color: rgba(0, 0, 0, 0.25);
				background: transparent;
				cursor: not-allowed;
			}

			.day-of-month {
				position: relative;
				z-index: 5;
			}

			// &:last-child {
			// 	margin-right: $ftOverrideWidth + $cardPadding!important;
			// }
			//border: 1px solid rgba(0, 0, 0, 0.05);
		}

		md-card-header {
			padding: 0;
			border-bottom: 2px solid rgba($fullday, 0.4);
			font-size: 12px;
			text-align: center;
			font-weight: bold;

			.invoice-date {
				line-height: 3.6em;
				border-bottom: 2px solid rgba($fullday, 0.4);
			}

			.day-of-week {
				min-width: $dateWidth;
				margin: 0 $dateSpacing;
				line-height: 3.6em;
			}

			.ft-override {
				//border-bottom: 2px solid rgba($fullday, 0.4);
				//line-height: 3em;
				padding-top: 0.6em;
				padding-bottom: 0.6em;
			}

		}

		md-card-actions {
			//position: relative;
			//display: flex;
			//flex-direction: row;
			//flex-wrap: wrap;
			//align-items: center;
			margin: 0;
			padding: 8px 0;
			text-align: left;
			border-bottom: 1px solid rgba(0,0,0,0.1);

			.highlighted {
				background-color: rgba(0,0,0,0.2);
			}

			&:last-of-type {
				border-bottom: 0;
			}

			.invoice-date {
				//height: 100%;
				border-top: 1px solid rgba(0,0,0, 0.1);
				margin-top: -8px;
				span {
					display: block;
					margin-top: 8px;
				}
				md-icon {
					min-width: auto;
					min-height: auto;
					font-size: 1em;
					vertical-align: top
				}

			}

			.ft-override {
				width: $ftOverrideWidth; //dumb dumb dumb				
				margin: 0;
				padding: 0;

				md-checkbox {
					margin: 0;
					left: 4px;

					&[disabled] {
						opacity: 0;
					}
				}
			}

			&.invoiced { opacity: 0.4; }
		}

		.invoice-date {
			text-align: left;
			width: $invoiceDateWidth - $cardPadding;
			position: absolute;			
			transform: translateX(- $invoiceDateWidth - $cardPadding);

			i {
				display: block;
				font-style: normal;
				font-size: 12px;
				color: rgba(0, 0, 0, 0.5);
			}
		}

		.ft-override {
			text-align: center;
			width: $ftOverrideWidth;
			margin-left: $dateSpacing!important;
			//position: absolute;
			// transform: translateX($calendarWidth - $cardPadding);
			// text-align: center;
			// 
			//background: rgba(0,0,0,0.05);

			//padding: 5px $cardPadding 5px $cardPadding
		}

	}
	
	.special-days {
		min-width: 380px;

		md-card-header {
			padding: 0;
			//border-bottom: 2px solid rgba($fullday, 0.4);
			font-size: 12px;
			margin-bottom: $cardMargin / 4;
		}

		.md-title {
			font-size: 18px;
			//margin-bottom: 7px;
		}

		md-card {
			//margin: 0;
			padding: 5px ( $cardPadding * 1.2 );
		}

		md-checkbox {
			margin-top: 7px;
		}

		md-input-container {
			margin-top: 0;
			margin-right: 0;
		}
	}


	// Day Type Classes
	.selected {
		border-radius: 40px !important;
		color: #ffffff;
		background-color: #6e297a;
		font-weight: bold!important;

		&.full-time-rate {
			box-shadow: 0 0 0px 5px $fulltimeRate;	
		}
	}

	
	
	.closed {
		background-color: $closed;

		&.selected {
			color: inherit;
			background-color: $closed;
		}

		// &:hover {
		// 	background-color: black!important;
		// }
	}

	.fullday {
		&.selected:not(.closed) {
			background-color: #740088;
      		color: #ffffff;
		}
	
	}
	
	
	//These styles are only allowed for school age programs
	.available {

		&.before, &.after {
			position: relative;
			overflow: hidden;

			&:after {
				content: "";
				backface-visibility: hidden;
				// background-color: rgba(151,56,168,0.7);
				
				height: 50%;
				width: 100%;
				position: absolute;
				z-index: 0;
				top: 0;
				left: 0;
				border-radius: 50px 50px 0 0;
				transition: transform 0.25s ease;
			}
		}

		&.before:after {
			background-color: rgba(77, 180, 220, 1);
		}
		&.after:after {
			background-color: rgba(215, 185, 40, 1);
		}

		// &.before:after {
		// 	background-color: lighten($fullday, 40%);
		// }
		// &.after:after {
		// 	background-color: lighten($fullday, 40%);
		// }

		&.before::after, &.closedbefore::after {
			transform: rotate(135deg);
			transform-origin: bottom;

			span {
				transform: rotate(-135deg);
				display: block;
			}
		}

		&.after::after, &.closedafter::after {
			transform: rotate(-45deg);
			transform-origin: bottom;

			span {
				transform: rotate(45deg);
				display: block;
			}
		}

		&.paday, &.paday[disabled] {
			background-color: $paday;
			color: #ffffff;
			opacity: 1;

			&.not-available {
				@extend .md-button[disabled];
			}
		}

		&.schoolbreak, &.schoolbreak[disabled] {
			background-color: $schoolbreak;
			color: #ffffff;
			opacity: 1;

			&.not-available {
				@extend .md-button[disabled];
			}
		}
	}

}