
$fullday: rgba(106, 27, 154, 1)!default;
$beforeSchool: rgba(77, 180, 220, 1)!default;
$afterSchool: rgba(215, 185, 40, 1)!default;
$schoolbreak: #ffa235;
$paday: #69be69;
$closed: #cccccc;
$fulltimeRate: rgba(234, 128, 252, 1)!default;

$cardPadding: 12px !default;
$dateWidth: 40px !default;
$dateSpacing: 4px !default;
$invoiceDateWidth: 170 + $cardPadding !default;
$calendarLegendBorder: 2px solid rgba($umbPurple, 0.4) !default;
$calendarWidth: ($dateWidth * 5) + ($dateSpacing * 10) + ($cardPadding * 2) !default;

//Variables for how the calendar renders will be based on a class added to the container holding the entire calendar

.fee-splitting { 

}

.attendance-payment {

}

.enrollment {

}

.subsidy {
	$fullday: rgba($fullday, 0.5);
	$schoolbreak: rgba(#ffa235, 0.5);
	$paday: rgba(#69be69, 0.5);
}


.umb-calendar {
	position: relative;

	.calendar-headers {
		width: 100%;
		font-size: 12px;
		line-height: 3em;
		font-weight: bold;
		border-bottom: 2px solid rgba($fullday, 0.4);
	}


	//Our Day type classes
	// Day Type Classes
	.selected {
		-webkit-print-color-adjust: exact;
		border-radius: 40px !important;
		color: #ffffff;
		background-color: $fullday;	
		font-weight: bold!important;

		&.full-time-rate {
			box-shadow: 0 0 0px 5px $fulltimeRate;	
		}
	}
	
	.closed {
		background-color: $closed;

		&.selected {
			color: inherit;
			background-color: $closed;
		}
	}

	.fullday {
		&.selected:not(.closed) {
			//background-color: #740088;
      		color: #ffffff;
		}
	}

	//These styles are only allowed for school age programs
	//.available {
	.fullday{


	}
	// 
	//These styles are only allowed for school age programs
	.available {

		&.before, &.after {
			position: relative;
			overflow: hidden;

			&:after {
				content: "";
				backface-visibility: hidden;
				// background-color: rgba(151,56,168,0.7);
				
				height: 50%;
				width: 100%;
				position: absolute;
				z-index: 0;
				top: 0;
				left: 0;
				border-radius: 50px 50px 0 0;
				transition: transform 0.25s ease;
			}
		}

		&.before:after {
			background-color: rgba(77, 180, 220, 1);
		}
		&.after:after {
			background-color: rgba(215, 185, 40, 1);
		}

		// &.before:after {
		// 	background-color: lighten($fullday, 40%);
		// }
		// &.after:after {
		// 	background-color: lighten($fullday, 40%);
		// }

		&.before::after, &.closedbefore::after {
			transform: rotate(135deg);
			transform-origin: bottom;

			span {
				transform: rotate(-135deg);
				display: block;
			}
		}

		&.after::after, &.closedafter::after {
			transform: rotate(-45deg);
			transform-origin: bottom;

			span {
				transform: rotate(45deg);
				display: block;
			}
		}

		&.paday, &.paday[disabled] {
			background-color: $paday;
			color: #ffffff;
			opacity: 1;

			&.not-available {
				@extend .md-button[disabled];
			}
		}

		&.schoolbreak, &.schoolbreak[disabled] {
			background-color: $schoolbreak;
			color: #ffffff;
			opacity: 1;

			&.not-available {
				@extend .md-button[disabled];
			}
		}
	}

	////
	// Top Level Class definitions
	
	.invoice-date {
		width: $invoiceDateWidth;
		display: inline-table;

		i {
			display: block;
			font-style: normal;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	.schedule {
		width: 100%;

		&:not(:last-of-type) {
			margin-bottom: 2em;
		}

		.schedule-title {
			margin: 1em 0;
			padding-top: 0;
			border-top: 1px solid rgba(0, 0, 0, 0.2);
			
			span {
				position: absolute;
				transform: translateY(-50%);
				background: #fff;
				padding-right: 1em;
			}
		}
	}

	.schedule-item {
		
	}

	.quick-selection {
		md-input-container {
			margin: 0 1em 0 0;

			> span {
				display: block;
				font-size: 12px;
				padding-bottom: 8px;
			}
		}

		md-checkbox {
			margin-right: 1.1em;

			.md-label {
				font-size: 14px;
			}
		}
	}

	.legend { 
		width: 100%;
		margin: 1.2em 0 1.5em;

		.item {
			display: inline-block;
			margin-right: 24px;
		}

		.full-time {
			padding-right: 1.5em;
			border-right: 1px solid rgba(0,0,0,0.3);
		}

		i {
			height: 15px;
			width: 15px;
			vertical-align: middle;
			display: inline-block;
			margin-right: 7px;
		}

		label {
			display: inline-block;
			vertical-align: middle;
			font-size: 13px;
			opacity: 0.6;		
		}
	}

	.cards-calendar-days {
		width: $calendarWidth;
	 }

	.cards-special-days { }

	.calendar { 
		//transform: translateX($invoiceDateWidth);

		md-card {
			margin: 0;
		}

		.invoice-date {
			position: absolute;			
			transform: translateX(-($invoiceDateWidth + $cardPadding));
		}

		md-card-header {
			padding: 0 $cardPadding;
			border-bottom: $calendarLegendBorder;
			font-size: 12px;
			text-align: center;
			font-weight: bold;

			.invoice-date {
				line-height: 3em;
				border-bottom: $calendarLegendBorder;
				text-align: left;
				
				i {
					display: block;
					font-style: normal;
					font-size: 12px;
					color: rgba(0, 0, 0, 0.5);
				}
			}

			.day-of-week {
				min-width: $dateWidth;
				margin: 0 $dateSpacing;
				line-height: 3em;
			}
		}

		md-card-actions {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			margin: 0;
			padding: $cardPadding/2 $cardPadding;
			text-align: left;
			border-bottom: 1px solid rgba(0,0,0,0.1);

			// .highlighted {
			// 	background-color: rgba(0,0,0,0.2);
			// }

			// &:last-of-type {
			// 	border-bottom: 0;
			// }

			.invoice-date {
				height: 100%;

				span {
					display: block;
					margin-top: $dateWidth / 2;
				}

				&:not(.first) {
					top: -1px; //subtract the border below
					border-top: 1px solid rgba(0,0,0, 0.1);
				}

			}

		}

		.md-button.day {
			padding: 0;
			margin: $dateSpacing;
			min-height: $dateWidth;
			min-width: $dateWidth;
			font-size: 16px;
			font-weight: normal;
			cursor: pointer;

			&:hover {
				background-color: $fullday;
				opacity: .5;
				color: #fff;
			}
			
			//overwrite material design setting
			&:first-of-type, &:last-of-type {
				margin: $dateSpacing; 
			}
			
			//This contains the day number
			span {
				position: relative;
				z-index: 5;
			}
		}
	

	}

	//////////


}