.login {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;

    background: url(../assets/img/login-bg.jpg) center center no-repeat;
	background-size: cover;
	
	md-card-header-text {
		text-align: center;
	}
	
	.logo img {
		margin-top: 1em;
		max-width: 180px;
	}

	.md-title {
		max-width: 300px;
	}
}