.action-message {
	
	text-align: center;
	padding: 2em;
	cursor: initial;

	.msg-container {
		text-align: left;
		display: inline-block;
		padding: 2em;
		background: rgba(0,0,0,0.05);
		border: 1px solid rgba(0,0,0,0.1);
		
	}

	h2 {
		text-align: center;
	}

	h3 {
		display: block;
		font-weight: bold;
		font-size: 14px;
		margin-top: 0;
		padding-bottom: 0.5em;
		text-align: center;
		border-bottom: 1px solid #6e297a;
	}
	
	ul, ol {
		padding-right: 40px;
	}
	li {
		margin-bottom: 0.25em;
		
	}

}

.flag {
	//Should make a mixin to output modifier classes (ex. .purple) for all the theme colours
	
	padding: 1.2% 0.5em;
	white-space: nowrap;
	width: auto;
	display: inline-block;
	vertical-align: middle;
	background: #ccc;
	font-size: 70%;
	text-transform: uppercase;
	position: relative;

	&.purple {
		color: #fff;
		background: $umbPurple;
	}
}